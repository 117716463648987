<template>
    <div class="a_m">
        <div class="a_m__b" :class="dragging && 'dragging'">
            <vueScroll :ops="ops">
                <draggable 
                    v-model="routersList"
                    :forceFallback="true"
                    ghost-class="ghost"
                    draggable=".a_i"
                    group="aside_menu"
                    :options="{delay: 300}"
                    @start="dragging = true"
                    @end="dragging = false">
                    <template v-for="item in routersList" >
                        <div v-if="item.apiLink" :key="item.name" class="a_m__b--i a_i api_link">
                            <a-popover trigger="click" placement="rightTop" overlayClassName="menu_popover" @visibleChange="visibleChange($event, item)">
                                <MenuItem :item="item" />
                                <template slot="content">
                                    <a-spin :spinning="loading">
                                        <div class="popover_menu">
                                            <a v-for="link in menuList" :key="link.code" :href="link.url" target="_blank">
                                                {{ link.name }}
                                            </a>
                                            <div v-if="empty" class="empty">Нет данных</div>
                                        </div>
                                    </a-spin>
                                </template>
                            </a-popover>
                        </div>
                        <router-link 
                            v-else
                            :key="item.name"
                            :to="{ name: item.name }"
                            class="a_m__b--i a_i">
                            <MenuItem :item="item" />
                        </router-link>
                    </template>
                </draggable>
            </vueScroll>
        </div>
    </div>
</template>

<script>
import vueScroll from 'vuescroll'
import mixins from '../mixins.js'
import MenuItem from './MenuItem.vue'
export default {
    components: {
        MenuItem,
        vueScroll
    },
    mixins: [mixins],
    data() {
        return {
            loading: false,
            menuList: [],
            empty: false
        }
    },
    methods: {
        visibleChange(visible, item) {
            if(visible) {
                this.getMenuLinks(item)
            } else {
                this.menuList = []
            }
        },
        async getMenuLinks(item) {
            try {
                this.empty = false
                this.loading = true
                const { data } = await this.$http.get(item.apiLink, {
                    params: item.params
                })
                if(data?.length) {
                    this.menuList = data
                } else {
                    this.empty = true
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.api_link{
    cursor: pointer;
}
.empty{
    color: #ffffff;
}
.a_m{
    background: #eff2f5;
    width: 100px;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 50;
    left: 0;
    border-right: 1px solid var(--border2);
    &__b{
        height: 100%;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
        &::-webkit-scrollbar-thumb{
            display: none;
        }
        &::v-deep{
            .__rail-is-vertical{
                background: transparent!important;
                .__bar-is-vertical{
                    border: 0px!important;
                }
            }
        }
        &.dragging{
            .a_m__b--i{
                &::v-deep{
                    .icon{
                        animation-delay: -0.65; 
                        animation-duration: .20s 
                    }
                }
                &:nth-child(2n) {
                    &::v-deep{
                        .icon{
                            animation-name: JiggleEffect;
                            animation-iteration-count: infinite;
                            transform-origin: 50% 10%;
                            -webkit-transition: all .2s ease-in-out;
                        }
                    }
                }
                &:nth-child(2n-1) {
                    &::v-deep{
                        .icon{
                            animation-name: JiggleEffect2;
                            animation-iteration-count: infinite;
                            animation-direction: alternate;
                            transform-origin: 30% 5%;
                        }
                    }
                }
            }
        }
    }
    .a_m__b--i{
        text-align: center;
        display: block;
        padding: 8px 10px 8px 10px;
        color: #354052;
        transition: color .3s ease;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        background: transparent;
        &:first-child{
            padding-top: 14px;
        }
        &:last-child{
            padding-bottom: 15px;
        }
        &:hover{
            background: #e3e6ea;
            color: var(--blue);
        }
        &.router-link-active{
            color: var(--blue);
        }
    }
}
</style>